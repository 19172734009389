import React from 'react';
import { Home } from './screens/Home';

const App = () => {

  return (
      <Home />
  );
};

export default App;

